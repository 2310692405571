<template>
  <div>
    <v-autocomplete
      v-model="userRoles"
      :items="allRoles"
      label="Role"
      item-text="name"
      item-value="id"
      :rules="validators"
      return-object
      multiple
      append-icon="mdi-chevron-down"
      outlined
      dense
      @change="changeUserPermissions"
    >
      <template #selection="{ index, item }">
        <MultiselectChoices
          :index="index"
          :label="item.name"
          :array-length="userRoles.length"
          :visible-items="3"
        />
      </template>
    </v-autocomplete>
    <PermissionsTable
      label="Dostępy do widoków"
      :permissions="viewPermissions"
      :selected-permissions="userViewsPermissions"
      @update="setUserPermissions($event, 'Widoki')"
    />
    <PermissionsTable
      class="mt-2"
      label="Dostępy do ustawień"
      :permissions="settingsPermissions"
      :selected-permissions="userSettingsPermissions"
      @update="setUserPermissions($event, 'Ustawienia')"
    />
    <PermissionsTable
      class="mt-2"
      label="Dostępy do funkcji"
      :permissions="functionsPermissions"
      :selected-permissions="userFunctionsPermissions"
      @update="setUserPermissions($event, 'Funkcje')"
    />
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import MultiselectChoices from '../../Elements/MultiselectChoices.vue'
import PermissionsTable from '../Elements/PermissionsTable.vue'

export default {
  components: {
    MultiselectChoices,
    PermissionsTable
  },
  mixins: [inputMixin],
  data() {
    return {
      userRoles: [],
      userViewsPermissions: [],
      userSettingsPermissions: [],
      userFunctionsPermissions: []
    }
  },
  mounted() {
    this.userViewsPermissions = this.data.permissions.filter(p => p.permissionType === 'Widoki')
    this.userSettingsPermissions = this.data.permissions.filter(p => p.permissionType === 'Ustawienia')
    this.userFunctionsPermissions = this.data.permissions.filter(p => p.permissionType === 'Funkcje')
    this.userRoles = this.data.roles
  },
  computed: {
    ...mapState({
      allRoles: state => state.core.filters.userRoles,
      allPermissions: state => state.core.filters.permissions
    }),
    viewPermissions() {
      return this.allPermissions.filter(p => p.permissionType === 'Widoki')
    },
    settingsPermissions() {
      return this.allPermissions.filter(p => p.permissionType === 'Ustawienia')
    },
    functionsPermissions() {
      return this.allPermissions.filter(p => p.permissionType === 'Funkcje')
    }
  },
  methods: {
    // set all permissions from selected userRoles to userPermissions
    changeUserPermissions() {
      const permissions = this.userRoles
        ?.map(userRole => {
          const role = this.allRoles.find(r => r.id === userRole.id)
          return role.defaultPermissions
        })
        .flat()
      const uniqueTempValues = new Set()
      const uniquePermissions = []

      permissions.forEach(obj => {
        const name = obj.name
        if (!uniqueTempValues.has(name)) {
          uniqueTempValues.add(name)
          uniquePermissions.push(obj)
        }
      })
      this.userViewsPermissions = uniquePermissions.filter(p => p.permissionType === 'Widoki')
      this.userSettingsPermissions = uniquePermissions.filter(p => p.permissionType === 'Ustawienia')
      this.userFunctionsPermissions = uniquePermissions.filter(p => p.permissionType === 'Funkcje')
      this.updateValues()
    },
    // add or delete permission from userPermissions by clicking checkbox
    setUserPermissions(permissions, type) {
      switch (type) {
        case 'Widoki':
          this.userViewsPermissions = permissions
          break
        case 'Ustawienia':
          this.userSettingsPermissions = permissions
          break
        case 'Funkcje':
          this.userFunctionsPermissions = permissions
          break
      }
      this.updateValues()
    },
    updateValues() {
      const roleIds = this.userRoles.map(r => r.id)
      const permissionIds = [
        ...this.userViewsPermissions,
        ...this.userSettingsPermissions,
        ...this.userFunctionsPermissions
      ].map(p => p.id)
      this.$emit('update', { roleIds, permissionIds })
    }
  }
}
</script>
